const ImageLogoComponent: React.FC<{ height?: string; width?: string }> = ({
  width = '122',
  height = '16',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 182 24"
      fill="none"
    >
      <mask
        id="mask0_2823_16972"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="25"
        height="24"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 0.000976562H24.7779V23.7871H0V0.000976562Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_2823_16972)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24.7683 11.354C24.7634 11.2657 24.7434 11.1805 24.7349 11.093C24.7318 10.981 24.7407 10.871 24.734 10.7582C24.3762 4.49833 18.5534 -0.311031 11.7274 0.0167877C4.90222 0.344199 -0.340536 5.685 0.0172786 11.9453C0.0239459 12.0577 0.0448369 12.166 0.0550602 12.278C0.0563937 12.366 0.0466149 12.4523 0.0519488 12.5407C0.0959533 13.3132 0.286195 14.0519 0.58267 14.7536C1.18984 16.5266 2.25129 18.1018 3.64298 19.3752C3.55631 20.7309 2.83268 22.4229 1.87747 23.3835C1.89525 23.3962 1.41298 23.7745 1.43076 23.7871C3.05137 23.6829 5.58718 22.3284 6.86731 21.5087C8.71506 22.3541 10.8148 22.7931 13.0244 22.6868C18.801 22.4095 23.4295 18.5367 24.5029 13.5592C24.7083 12.8465 24.8114 12.1098 24.7683 11.354ZM17.5207 10.7332L17.5225 11.8962L18.6457 12.4651C18.5484 12.9143 18.3928 13.3447 18.191 13.7516L16.9175 13.6123L16.1739 14.5542L16.7148 15.6183C16.3535 15.9245 15.9503 16.1917 15.5192 16.4161L14.5795 15.6171L13.3727 15.9771L13.1278 17.1287C12.8958 17.1527 12.6615 17.1666 12.4233 17.169C12.161 17.1702 11.9046 17.1552 11.6534 17.1287L11.405 15.9775L10.1982 15.6183L9.26073 16.4157C8.82824 16.1937 8.42865 15.9262 8.06772 15.6216L8.606 14.5575L7.86103 13.6151L6.58935 13.756C6.38577 13.35 6.23198 12.9188 6.1333 12.4696L7.25475 11.8991L7.25831 10.7356L6.13819 10.1639C6.23376 9.72815 6.38355 9.30992 6.57824 8.91573L7.8517 9.05011L8.59222 8.10616L8.04816 7.04411C8.4082 6.73828 8.80691 6.46951 9.23851 6.24594L10.1799 7.03963L11.3858 6.67638L11.6276 5.52514C11.8654 5.49827 12.1086 5.48239 12.3535 5.48198C12.606 5.47994 12.8562 5.49379 13.1029 5.51822L13.3532 6.66986L14.5635 7.02497L15.4983 6.22273C15.9299 6.44344 16.3299 6.71018 16.6935 7.01479L16.1583 8.0805L16.9077 9.01957L18.1794 8.87541C18.3839 9.28101 18.5399 9.71145 18.6404 10.1606L17.5207 10.7332Z"
          fill="#282828"
        />
      </g>
      <mask
        id="mask1_2823_16972"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="182"
        height="24"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 24H181.848V0H0V24Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1_2823_16972)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M29.3438 19.9881H34.699V1.03613H29.3438V19.9881Z"
          fill="#282828"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.6162 16.1246V16.0726C35.6162 13.0773 38.0254 11.5666 41.6237 11.5666C43.0408 11.5666 44.4299 11.806 45.3078 12.1232V11.8838C45.3078 10.373 44.317 9.52492 42.2482 9.52492C40.6329 9.52492 39.3856 9.84461 37.9965 10.3215L36.8911 6.79525C38.6206 6.13258 40.4622 5.68359 43.0986 5.68359C45.8465 5.68359 47.717 6.29222 48.9354 7.43173C50.0987 8.49142 50.5796 9.97598 50.5796 11.9918V20.2337H45.2802V18.7229C44.2027 19.8104 42.7581 20.5509 40.7458 20.5509C37.8267 20.5509 35.6162 18.9603 35.6162 16.1246ZM45.3657 15.0388V14.3765C44.7976 14.1649 44.033 14.0053 43.2387 14.0053C41.6807 14.0053 40.7739 14.6937 40.7739 15.7813V15.8332C40.7739 16.8152 41.5669 17.3976 42.6724 17.3976C44.2602 17.3976 45.3657 16.4697 45.3657 15.0388Z"
        fill="#282828"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M57.3052 18.4831V20.2333H51.9209V0.885742H57.3052V7.80262C58.3525 6.60907 59.6566 5.57557 61.9525 5.57557C65.6077 5.57557 68.7543 8.41333 68.7543 12.9971V13.0512C68.7543 17.7148 65.6077 20.5505 61.9805 20.5505C59.6566 20.5505 58.296 19.5706 57.3052 18.4831ZM63.4825 13.0775V13.0255C63.4825 11.1156 62.0663 9.76453 60.3381 9.76453C58.5805 9.76453 57.1914 11.1156 57.1914 13.0255V13.0775C57.1914 14.9848 58.5805 16.338 60.3381 16.338C62.0663 16.338 63.4825 15.0131 63.4825 13.0775Z"
        fill="#282828"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M69.0762 13.1306V13.0766C69.0762 8.99581 72.6469 5.5752 77.5493 5.5752C82.4518 5.5752 85.9954 8.94176 85.9954 13.0246V13.0766C85.9954 17.1574 82.4238 20.5784 77.4933 20.5784C72.618 20.5784 69.0762 17.2114 69.0762 13.1306ZM80.7812 13.1305V13.0765C80.7812 11.3005 79.3931 9.81552 77.4946 9.81552C75.5383 9.81552 74.291 11.2743 74.291 13.0245V13.0765C74.291 14.8267 75.6516 16.3092 77.5506 16.3092C79.5064 16.3092 80.7812 14.8529 80.7812 13.1305Z"
        fill="#282828"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M86.8535 5.89502H92.2383V8.78267C93.1166 6.82293 94.5328 5.54955 97.0843 5.65556V10.9582H96.6304C93.8252 10.9582 92.2383 12.4689 92.2383 15.7532V20.2335H86.8535V5.89502Z"
        fill="#282828"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M96.835 16.1246V16.0726C96.835 13.0773 99.2437 11.5666 102.843 11.5666C104.259 11.5666 105.648 11.806 106.527 12.1232V11.8838C106.527 10.373 105.535 9.52492 103.467 9.52492C101.851 9.52492 100.604 9.84461 99.2153 10.3215L98.1098 6.79525C99.8394 6.13258 101.681 5.68359 104.317 5.68359C107.066 5.68359 108.936 6.29222 110.154 7.43173C111.317 8.49142 111.798 9.97598 111.798 11.9918V20.2337H106.499V18.7229C105.421 19.8104 103.976 20.5509 101.965 20.5509C99.0459 20.5509 96.835 18.9603 96.835 16.1246ZM106.583 15.0388V14.3765C106.015 14.1649 105.251 14.0053 104.456 14.0053C102.898 14.0053 101.991 14.6937 101.991 15.7813V15.8332C101.991 16.8152 102.785 17.3976 103.89 17.3976C105.477 17.3976 106.583 16.4697 106.583 15.0388Z"
        fill="#282828"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M118.523 18.4831V20.2333H113.139V0.885742H118.523V7.80262C119.571 6.60907 120.875 5.57557 123.171 5.57557C126.826 5.57557 129.972 8.41333 129.972 12.9971V13.0512C129.972 17.7148 126.826 20.5505 123.198 20.5505C120.875 20.5505 119.514 19.5706 118.523 18.4831ZM124.699 13.0775V13.0255C124.699 11.1157 123.282 9.76456 121.554 9.76456C119.797 9.76456 118.408 11.1157 118.408 13.0255V13.0775C118.408 14.9849 119.797 16.338 121.554 16.338C123.282 16.338 124.699 15.0131 124.699 13.0775Z"
        fill="#282828"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M130.471 4.54692H136.108V0.881836H130.471V4.54692ZM130.586 19.9954H135.994V5.82996H130.586V19.9954Z"
        fill="#282828"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M137.771 19.9954H143.18V0.881836H137.771V19.9954Z"
        fill="#282828"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M144.843 4.54691H150.48V0.881836H144.843V4.54691ZM144.957 19.9954H150.366V5.82999H144.957V19.9954Z"
        fill="#282828"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M153.692 15.2692V9.48283H151.878V5.50234H153.692V1.91406H159.05V5.50234H162.622V9.48283H159.05V14.2991C159.05 15.2946 159.532 15.7148 160.524 15.7148C161.232 15.7148 161.913 15.5316 162.565 15.2417V19.1174C161.602 19.614 160.325 19.9552 158.824 19.9552C155.591 19.9552 153.692 18.6464 153.692 15.2692Z"
        fill="#282828"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M174.94 5.50195H180.468L174.856 19.3261C173.523 22.6519 172.077 23.9354 169.015 23.9354C167.116 23.9354 165.528 23.4643 164.111 22.7053L165.755 19.2214C166.633 19.6928 167.513 19.9803 168.108 19.9803C168.646 19.9803 169.015 19.8505 169.384 19.4049L163.374 5.50195H168.987L172.049 14.0387L174.94 5.50195Z"
        fill="#282828"
      />
    </svg>
  );
};

export default ImageLogoComponent;
