import { createHashRouter, Navigate, RouterProvider } from 'react-router-dom';
import Layout from './components/Layout';
import {
  UsersPage,
  TagsPage,
  CategoriesPage,
  BenefitsPage,
  CompaniesPage,
  NoPage404,
  MisurePage,
  EntesPage,
  ContractsPage,
  Home,
} from './pages';

export const APP_ROUTES = {
  HOME: 'home',
  USER: 'user',
  TAGS: 'tags',
  CATEGORIES: 'categories',
  BENEFITS: 'benefits',
  COMPANIES: 'companies',
  MEASURES: 'measures',
  ENTES: 'enti',
  CONTRACTS: 'contracts',
};

export default function AppRoutes() {
  const router = createHashRouter([
    {
      path: '/',
      element: <Layout />,
      children: [
        { index: true, path: '/', element: <Navigate to={APP_ROUTES.HOME} /> },
        {
          path: APP_ROUTES.HOME,
          element: <Home />,
        },
        {
          path: APP_ROUTES.USER,
          element: <UsersPage />,
        },
        {
          path: APP_ROUTES.TAGS,
          element: <TagsPage />,
        },
        {
          path: APP_ROUTES.CATEGORIES,
          element: <CategoriesPage />,
        },
        {
          path: APP_ROUTES.BENEFITS,
          element: <BenefitsPage />,
        },
        {
          path: APP_ROUTES.COMPANIES,
          element: <CompaniesPage />,
        },
        {
          path: APP_ROUTES.MEASURES,
          element: <MisurePage />,
        },
        {
          path: APP_ROUTES.ENTES,
          element: <EntesPage />,
        },
        {
          path: APP_ROUTES.CONTRACTS,
          element: <ContractsPage />,
        },
        {
          path: '/*',
          element: <NoPage404 />,
        },
      ],
    },
    { index: true, path: '/*', element: <Navigate to={APP_ROUTES.HOME} /> },
  ]);

  return <RouterProvider router={router} />;
}
