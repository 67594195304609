import { Button, ButtonProps } from '@mui/material';
import styled from 'styled-components';
import { COLORS } from '../../utils';

export const StyledBox = styled(Button)<
  ButtonProps & {
    datatestid?: string;
    'data-track': string;
  }
>(({ datatestid, ...props }) => ({
  datatestid,
  'data-track': props['data-track'],
  variant: 'text',
  '&.MuiButton-root': {
    display: 'flex',
    justifyContent: 'space-between',
    height: '52px',

    border: `1px solid ${COLORS.getInstance().BLACK}4D`,
    borderRadius: '12px',
    '& .MuiRadio-root': {
      color: COLORS.getInstance().NEUTRAL,
    },

    '&:hover': {
      backgroundColor: COLORS.getInstance().PRIMARY_IPERLIGHT,
      '& .MuiRadio-root': {
        color: COLORS.getInstance().PRIMARY_DARK,
      },
    },
    '&:active': {
      border: `2px solid ${COLORS.getInstance().PRIMARY_DARK}`,
      backgroundColor: `${COLORS.getInstance().PRIMARY_MAIN}4D`,
    },
  },
}));
