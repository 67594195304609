import { createContext } from 'react';
import Keycloak from 'keycloak-js';

interface KeycloakContextProps {
  keycloak: Keycloak | undefined;
}

export const KeycloakContext = createContext<KeycloakContextProps>({
  keycloak: undefined,
});
