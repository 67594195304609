import { Checkbox, CheckboxProps, Stack } from '@mui/material';
import LBTLabel from '../Label';
import { COLORS } from '../../utils/Colors';
import { CheckboxStyle, CheckboxType } from '../../enums';
import styled from 'styled-components';
import { IconCheckbox } from '../Icons';

export type LBTCheckboxProps = Omit<CheckboxProps, 'onChange'> & {
  variant?: CheckboxStyle;
  label?: string;
  direction?: 'row' | 'row-reverse';
  datatestid?: string;
  onChange: (val?: boolean) => void;
};

const StyledCheckbox = styled(Checkbox)<{
  datatestid: string;
  variant: string;
}>(({ datatestid }) => ({
  datatestid,
}));

export default function LBTCheckbox({
  label,
  direction = 'row',
  variant = CheckboxStyle.PRIMARY,
  datatestid,
  onChange,
  ...props
}: LBTCheckboxProps) {
  return (
    <Stack direction={direction} justifyContent="center" alignItems="center">
      <StyledCheckbox
        icon={
          <IconCheckbox
            style={variant}
            type={CheckboxType.UNCHECKED}
            disabled={props.disabled}
          />
        }
        checkedIcon={
          <IconCheckbox
            style={variant}
            type={CheckboxType.CHECKED}
            disabled={props.disabled}
          />
        }
        indeterminateIcon={
          <IconCheckbox
            style={variant}
            type={CheckboxType.INTERMEDIATE}
            disabled={props.disabled}
          />
        }
        datatestid={`lbt-checkbox-${datatestid}`}
        variant={variant}
        onChange={e => onChange(e.target.checked)}
        {...props}
      />
      {label && (
        <LBTLabel
          color={props.disabled ? COLORS.getInstance().DISABLED : undefined}
          variant="inputFormLabel"
          component="label"
          datatestid={datatestid}
        >
          {label}
        </LBTLabel>
      )}
    </Stack>
  );
}
