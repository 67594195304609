export type Ente = {
  id?: number;
  name?: string;
  is_public_agency?: boolean;
  contract_id?: number;
};

export interface GetAllEnte {
  is_public_agency?: boolean;
}

export class EnteClass implements Ente {
  id = 0;
  name = '';
  is_public_agency = false;
  contract_id = 0;
}
