import {
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilCallback, useSetRecoilState } from 'recoil';
import { APP_ROUTES } from '../Routes';
import {
  getAllCategoriesCallback,
  getAllBenefitsCallback,
  getAllTagsCallback,
  getAllCompaniesCallback,
  getAllEnteCallback,
  getAllMisureCallback,
} from '@laborability/commons';
import { appBarState } from '../recoil/states/appBar';

export const Home = () => {
  const navigate = useNavigate();
  const getCats = useRecoilCallback(getAllCategoriesCallback, []);
  const geBenefits = useRecoilCallback(getAllBenefitsCallback, []);
  const getEnti = useRecoilCallback(getAllEnteCallback, []);
  const getTags = useRecoilCallback(getAllTagsCallback, []);
  const getCompanies = useRecoilCallback(getAllCompaniesCallback, []);
  const getMisure = useRecoilCallback(getAllMisureCallback, []);
  const setAppBarName = useSetRecoilState(appBarState);

  const [cats, setCats] = useState<number>(0);
  const [benefits, setBenefits] = useState<number>(0);
  const [enti, setEenti] = useState<number>(0);
  const [tags, setTags] = useState<number>(0);
  const [comp, setComp] = useState<number>(0);
  const [misure, setMisure] = useState<number>(0);

  const fetchData = async () => {
    const c = await getCats({ limit: 0 });
    setCats(c?.data.total ?? 0);

    const b = await geBenefits({ limit: 0 });
    setBenefits(b?.data.total ?? 0);

    const e = await getEnti({ limit: 100000 });
    setEenti(e?.data.total ?? 0);

    const t = await getTags({ limit: 0 });
    setTags(t?.data.total ?? 0);

    const a = await getCompanies({ limit: 0 });
    setComp(a?.data.total ?? 0);

    const m = await getMisure({ limit: 0 });
    setMisure(m?.data.total ?? 0);
  };

  useEffect(() => {
    fetchData();
    setAppBarName('BO App');
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item mobile={6}>
        <Card sx={{ maxWidth: 345 }}>
          {/* <CardContent>
            <Typography
              sx={{ fontSize: 14 }}
              color="text.secondary"
              gutterBottom
            >
              UTENTI REGISTRATI
            </Typography>
            <Typography variant="h5" component="div">
              5
            </Typography>
          </CardContent> */}
          <CardActions>
            <Button
              size="small"
              onClick={() => navigate('/' + APP_ROUTES.USER)}
            >
              Vedi utenti
            </Button>
          </CardActions>
        </Card>
      </Grid>
      <Grid item mobile={6}>
        <Card sx={{ maxWidth: 345 }}>
          <CardContent>
            <Typography
              sx={{ fontSize: 14 }}
              color="text.secondary"
              gutterBottom
            >
              AZIENDE REGISTRATE
            </Typography>
            <Typography variant="h5" component="div">
              {comp}
            </Typography>
          </CardContent>
          <CardActions>
            <Button
              size="small"
              onClick={() => navigate('/' + APP_ROUTES.COMPANIES)}
            >
              Vedi
            </Button>
          </CardActions>
        </Card>
      </Grid>

      <Grid item mobile={6}>
        <Card sx={{ maxWidth: 345 }}>
          <CardContent>
            <Typography
              sx={{ fontSize: 14 }}
              color="text.secondary"
              gutterBottom
            >
              CATEGORIE REGISTRATE
            </Typography>
            <Typography variant="h5" component="div">
              {cats}
            </Typography>
          </CardContent>
          <CardActions>
            <Button
              size="small"
              onClick={() => navigate('/' + APP_ROUTES.CATEGORIES)}
            >
              Vedi
            </Button>
          </CardActions>
        </Card>
      </Grid>
      <Grid item mobile={6}>
        <Card sx={{ maxWidth: 345 }}>
          <CardContent>
            <Typography
              sx={{ fontSize: 14 }}
              color="text.secondary"
              gutterBottom
            >
              BENEFIT REGISTRATI
            </Typography>
            <Typography variant="h5" component="div">
              {benefits}
            </Typography>
          </CardContent>
          <CardActions>
            <Button
              size="small"
              onClick={() => navigate('/' + APP_ROUTES.BENEFITS)}
            >
              Vedi
            </Button>
          </CardActions>
        </Card>
      </Grid>

      <Grid item mobile={6}>
        <Card sx={{ maxWidth: 345 }}>
          <CardContent>
            <Typography
              sx={{ fontSize: 14 }}
              color="text.secondary"
              gutterBottom
            >
              ENTI REGISTRATI
            </Typography>
            <Typography variant="h5" component="div">
              {enti}
            </Typography>
          </CardContent>
          <CardActions>
            <Button
              size="small"
              onClick={() => navigate('/' + APP_ROUTES.ENTES)}
            >
              Vedi
            </Button>
          </CardActions>
        </Card>
      </Grid>
      <Grid item mobile={6}>
        <Card sx={{ maxWidth: 345 }}>
          <CardContent>
            <Typography
              sx={{ fontSize: 14 }}
              color="text.secondary"
              gutterBottom
            >
              MISURE REGISTRATE
            </Typography>
            <Typography variant="h5" component="div">
              {misure}
            </Typography>
          </CardContent>
          <CardActions>
            <Button
              size="small"
              onClick={() => navigate('/' + APP_ROUTES.MEASURES)}
            >
              Vedi
            </Button>
          </CardActions>
        </Card>
      </Grid>
      <Grid item mobile={6}>
        <Card sx={{ maxWidth: 345 }}>
          <CardContent>
            <Typography
              sx={{ fontSize: 14 }}
              color="text.secondary"
              gutterBottom
            >
              TAG REGISTRATI
            </Typography>
            <Typography variant="h5" component="div">
              {tags}
            </Typography>
          </CardContent>
          <CardActions>
            <Button
              size="small"
              onClick={() => navigate('/' + APP_ROUTES.TAGS)}
            >
              Vedi
            </Button>
          </CardActions>
        </Card>
      </Grid>
    </Grid>
  );
};
