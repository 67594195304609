import Select, { SelectProps } from '@mui/material/Select';
import Box, { BoxProps } from '@mui/material/Box';
import React from 'react';
import styled from 'styled-components';
import {
  CheckboxStyle,
  IconArrowDownComponent,
  IconCrossComponent,
  IconWarningComponent,
  IconsSize,
  IconsStyle,
  LBTCheckbox,
  LBTListItem,
} from '../..';
import { COLORS } from '../../utils/Colors';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Stack,
} from '@mui/material';
import LBTChip from '../Chip';
import LBTLabel from '../Label';
import { KeyValue } from '@laborability/commons';

const MenuProps = {
  PaperProps: {
    style: {
      borderRadius: '12px',
      maxWidth: 'min-content',
      maxHeight: '300px',
    },
  },
  sx: { backgroundColor: 'transparent' },
};

export type LBTSelectProps = SelectProps & {
  items: (KeyValue & { description?: string; [x: string]: any })[];
  handleChange: (e: any) => void;
  hasFullWidth?: boolean;
  helperText?: string;
  datatestid?: string;
};

const StyledSelect = styled(Select)<
  SelectProps & {
    helperText?: string;
    datatestid?: string;
    'data-track': string;
  }
>(({ helperText, datatestid, ...props }) => ({
  helperText,
  datatestid,
  'data-track': props['data-track'],
  '& .MuiSelect-root': {
    height: '52px',
  },
}));

const StyledIconComponentBox = styled(Box)<BoxProps>(() => ({
  width: '20px',
  height: '20px',
  marginRight: '16px',
}));

const LBTSelect: React.FC<LBTSelectProps> = ({
  items,
  handleChange,
  helperText,
  datatestid = '',
  variant = 'outlined',
  value,
  error,
  label,
  required,
  multiple,
  hasFullWidth = true,
  ...props
}: LBTSelectProps) => {
  return (
    <FormControl fullWidth={hasFullWidth} sx={{ maxWidth: '680px' }}>
      <InputLabel required={required}>{label}</InputLabel>
      <StyledSelect
        size="small"
        variant={variant}
        MenuProps={MenuProps}
        onChange={e => handleChange(e.target.value)}
        value={value}
        label={label}
        fullWidth
        IconComponent={() => (
          <StyledIconComponentBox>
            <IconArrowDownComponent
              size={IconsSize.MEDIUM}
              style={IconsStyle.OUTLINE}
              color={COLORS.getInstance().BLACK}
            />
          </StyledIconComponentBox>
        )}
        renderValue={(selected: any) => {
          return multiple ? (
            <Stack gap={1} direction="row" flexWrap="wrap">
              {selected.map((chip: any, index: number) => (
                <LBTChip
                  key={index}
                  label={items.find(item => item.id === chip)?.name}
                  color="default"
                  onDelete={() => {
                    const array = value as Array<any>;
                    handleChange(array.filter(item => item !== chip));
                  }}
                  deleteIcon={
                    <div onMouseDown={event => event.stopPropagation()}>
                      <IconCrossComponent
                        size={IconsSize.SMALL}
                        style={IconsStyle.OUTLINE}
                        color={COLORS.getInstance().BLACK}
                      />
                    </div>
                  }
                />
              ))}
            </Stack>
          ) : (
            <LBTLabel
              variant="inputFormLabel"
              component="label"
              textAlign="left"
            >
              {items.find(item => item.id === selected)?.name ?? ''}
            </LBTLabel>
          );
        }}
        datatestid={`lbt-text-field-${datatestid}`}
        data-track={datatestid}
        required={required}
        multiple={multiple}
        error={error}
        {...props}
      >
        {!multiple && !required && (
          <MenuItem key={'remove_item'} value={undefined}>
            <LBTLabel variant="listTitle">-</LBTLabel>
          </MenuItem>
        )}
        {items.map(option => (
          <MenuItem key={option.id} value={option.id}>
            {multiple && Array.isArray(value) && (
              <LBTCheckbox
                variant={CheckboxStyle.PRIMARY}
                checked={value.indexOf(option.id) > -1}
                sx={{ marginRight: '18px', padding: '11px' }}
                onChange={() => {}}
              />
            )}
            <LBTListItem
              title={option.name as string}
              description={option.description}
              titleStyle={{ textWrap: 'wrap' }}
              descriptionStyle={{ textWrap: 'wrap' }}
              descrptionComponent="small"
            />
          </MenuItem>
        ))}
      </StyledSelect>
      {helperText && (
        <FormHelperText>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            {error && (
              <IconWarningComponent
                size={IconsSize.MEDIUM}
                style={IconsStyle.OUTLINE}
                color={COLORS.getInstance().ERROR_MAIN}
              />
            )}
            <LBTLabel
              color={
                error
                  ? COLORS.getInstance().ERROR_MAIN
                  : COLORS.getInstance().BW_GREYS_MEDIUM
              }
              variant="inputHelper"
              component="label"
              style={{ textAlign: 'left' }}
            >
              {helperText}
            </LBTLabel>
          </Box>
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default LBTSelect;
