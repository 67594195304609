import { ColDef } from 'ag-grid-community';
import { useRecoilCallback, useSetRecoilState } from 'recoil';
import GridLayout from '../components/GridLayout';
import { CompanyForm } from '../components/Forms';
import { useGrid } from '../hooks/useGrid';
import {
  deleteCompanyCallback,
  getAllCompaniesCallback,
  Company,
  companiesState,
} from '@laborability/commons';
import { useEffect } from 'react';
import { appBarState } from '../recoil/states/appBar';

const columnDefs: ColDef[] = [
  {
    field: 'id',
    maxWidth: 100,
    filter: 'agNumberColumnFilter',
    resizable: false,
    headerName: 'ID',
  },
  {
    field: 'name',
    flex: 2,
    filter: 'agTextColumnFilter',
    headerName: 'Ragione sociale',
  },
  {
    field: 'address',
    flex: 1,
    filter: 'agTextColumnFilter',
    headerName: 'Indirizzo',
  },
  {
    field: 'phone_number',
    flex: 1,
    filter: 'agTextColumnFilter',
    headerName: 'Telefono',
  },
  {
    field: 'postal_code',
    flex: 1,
    filter: 'agTextColumnFilter',
    headerName: 'Codice Postale',
  },
  {
    field: 'city',
    flex: 1,
    filter: 'agTextColumnFilter',
    headerName: 'Città',
  },
  {
    field: 'region',
    flex: 1,
    filter: 'agTextColumnFilter',
    headerName: 'Regione',
  },
  {
    field: 'country',
    flex: 1,
    filter: 'agTextColumnFilter',
    headerName: 'Paese',
  },
  {
    field: 'email',
    flex: 1,
    filter: 'agTextColumnFilter',
    headerName: 'Email',
  },
  {
    field: 'bank_details',
    flex: 1,
    filter: 'agTextColumnFilter',
    headerName: 'Banca',
  },
];

export function CompaniesPage() {
  const getAllCompanies = useRecoilCallback(getAllCompaniesCallback, []);
  const deleteCompany = useRecoilCallback(deleteCompanyCallback, []);
  const setAppBarName = useSetRecoilState(appBarState);

  const { rowData, handleFetchAll, handleDelete } = useGrid<Company>({
    state: companiesState,
    onFetch: getAllCompanies,
    onDelete: deleteCompany,
  });

  useEffect(() => {
    setAppBarName('Registrazione aziende');
  }, []);

  return (
    <GridLayout<Company>
      pageName="Azienda"
      columnDefs={columnDefs}
      rowData={rowData}
      handleFetchAll={handleFetchAll}
      handleDelete={handleDelete}
      FormComponent={CompanyForm}
      recordNameAccessor={item => item.name ?? ''}
    />
  );
}
