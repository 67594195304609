import { GetFilter } from './common';

export type CompanyConfig = {
  id?: number;
  key: string;
  value?: string;
  company_id?: number;
};

export interface GetCompanyConfigurationByRealmname extends GetFilter {
  name: string;
}

export interface PutCompanyConfiguration {
  id: number;
  config: Omit<CompanyConfig, 'company_id'>[];
}

export interface CurrentCompanyConfig {
  id?: number;
  logo?: string;
  assocaf?: boolean;
  fifth_assignment?: boolean;
  tfr?: boolean;
}

export class CompanyConfigClass implements CompanyConfig {
  id = 0;
  key = '';
  value = '';
  company_id = 0;
}
