import { useRef, useState } from 'react';
import Keycloak from 'keycloak-js';
import { getRealmFromUrl } from '../utils';

export interface Tokens {
  token?: string;
  refreshToken?: string;
  idToken?: string;
}

export interface Returns {
  keycloak?: Keycloak;
  init: () => Promise<void>;
  getTokens: () => Tokens;
  isInitialized: boolean;
}

export default function useKeycloak(): Returns {
  let keycloak = useRef<Keycloak | undefined>(undefined);
  const [isInitialized, setIsInitialized] = useState(false);

  const saveTokens = () => {
    sessionStorage.setItem('token', keycloak.current?.token ?? '');
    sessionStorage.setItem(
      'refreshToken',
      keycloak.current?.refreshToken ?? '',
    );
    sessionStorage.setItem('idToken', keycloak.current?.idToken ?? '');
  };
  const getTokens = () => {
    return {
      token: sessionStorage.getItem('token') ?? undefined,
      refreshToken: sessionStorage.getItem('refreshToken') ?? undefined,
      idToken: sessionStorage.getItem('idToken') ?? undefined,
    };
  };
  const deleteTokens = () => {
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('refreshToken');
    sessionStorage.removeItem('idToken');
  };

  const init = async () => {
    keycloak.current = new Keycloak({
      url: process.env.REACT_APP_KEYCLOAK_URL,
      realm:
        getRealmFromUrl() ?? process.env.REACT_APP_KEYCLOAK_REALM_NAME ?? '',
      clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID ?? '',
    });

    try {
      const isAuthenticated = await keycloak.current.init({
        onLoad: 'check-sso',
        silentCheckSsoRedirectUri:
          window.location.origin + '/silent-check-sso.html',
        responseMode: 'query',
        ...getTokens(),
      });

      setIsInitialized(true);
      if (!isAuthenticated) {
        console.log('Not authenticated');
        deleteTokens();
        keycloak.current.logout();
        keycloak.current.login();
      }

      saveTokens();

      keycloak.current.onTokenExpired = () => {
        keycloak.current
          ?.updateToken(30)
          .then(refreshed => {
            if (!refreshed) {
              console.log('Failed to refresh token');
              deleteTokens();
              keycloak.current?.logout();
              keycloak.current?.login();
            } else saveTokens();
          })
          .catch(e => {
            console.log('Failed to refresh token', e);
            deleteTokens();
            keycloak.current?.logout();
            keycloak.current?.login();
          });
      };
    } catch (e) {
      console.log('Keycloak init', e);
    }
  };

  return { keycloak: keycloak.current, init, getTokens, isInitialized };
}
