import { AppTheme, KeycloakContext } from '@laborability/components';
import { Alert, Box, Snackbar } from '@mui/material';
import { useContext, useEffect, useMemo } from 'react';
import { Outlet } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { ThemeProvider } from 'styled-components';
import ApplicationBar from './ApplicationBar';
import { snackBarState } from '@laborability/commons';

const Layout = () => {
  const { keycloak } = useContext(KeycloakContext);

  const theme = useMemo(() => {
    return AppTheme;
  }, []);
  const [
    { duration, show: openSnackbar, severity, message, code },
    setSnackBar,
  ] = useRecoilState(snackBarState);

  useEffect(() => {
    if (code && code === 401) keycloak?.logout();
  }, [code]);

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackBar(prev => ({
      ...prev,
      show: false,
    }));
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: 'flex' }}>
        <ApplicationBar />
        <Box component="main" sx={{ flexGrow: 1, p: 3, mt: '55px' }}>
          <Outlet />
        </Box>
      </Box>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={duration}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert onClose={handleClose} severity={severity} variant="filled">
          {message}
        </Alert>
      </Snackbar>
    </ThemeProvider>
  );
};

export default Layout;
