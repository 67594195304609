import Button, { ButtonProps } from '@mui/material/Button';
import React from 'react';
import styled from 'styled-components';
import { COLORS } from '../../utils';
import { TypographyProps } from '@mui/material';
import LBTLabel from '../Label';

export type LBTButtonProps = ButtonProps & {
  onClick: () => void;
  datatestid?: string;
  hasFocus?: boolean;
  backgroundColor?: string;
  borderColor?: string;
  textProps?: TypographyProps;
};

function getFocusColors(variant: string, color: string) {
  if (variant === 'filter')
    return {
      color: COLORS.getInstance().PRIMARY_IPERLIGHT,
      backgroundColor: `${COLORS.getInstance().PRIMARY_DARK}`,
      borderColor: COLORS.getInstance().PRIMARY_DARK,
    };
  if (variant === 'contained') {
    if (color === 'primary')
      return {
        color: COLORS.getInstance().BW_GREYS_JET_BLACK,
        backgroundColor: `${COLORS.getInstance().PRIMARY_SUPERLIGHT}`,
        borderColor: COLORS.getInstance().PRIMARY_DARK,
      };
    return {
      color: COLORS.getInstance().WHITE,
      backgroundColor: COLORS.getInstance().BW_GREYS_MEDIUM,
      borderColor: COLORS.getInstance().PRIMARY_CONTRAST,
    };
  } else if (variant === 'outlined') {
    if (color === 'primary')
      return {
        color: COLORS.getInstance().PRIMARY_DARK,
        backgroundColor: COLORS.getInstance().PRIMARY_SUPERLIGHT,
        borderColor: COLORS.getInstance().PRIMARY_DARK,
      };
    return {
      color: COLORS.getInstance().BW_GREYS_JET_BLACK,
      backgroundColor: `${COLORS.getInstance().BLACK}33`,
      borderColor: COLORS.getInstance().BW_GREYS_JET_BLACK,
    };
  } else {
    if (color === 'primary')
      return {
        color: COLORS.getInstance().PRIMARY_DARK,
        backgroundColor: COLORS.getInstance().PRIMARY_SUPERLIGHT,
        borderColor: COLORS.getInstance().PRIMARY_DARK,
      };
    return {
      color: COLORS.getInstance().BW_GREYS_JET_BLACK,
      backgroundColor: `${COLORS.getInstance().BLACK}33`,
      borderColor: COLORS.getInstance().PRIMARY_CONTRAST,
    };
  }
}

const StyledButton = styled(Button)<
  ButtonProps & {
    backgroundColor?: string;
    borderColor?: string;
    datatestid: string;
    'data-track': string;
    hasFocus?: boolean;
  }
>(({
  backgroundColor,
  borderColor,
  datatestid,
  hasFocus = false,
  variant,
  color,
  ...props
}) => {
  const {
    color: focusColor,
    backgroundColor: focusBackgroundColor,
    borderColor: focusBorderColor,
  } = getFocusColors(variant ?? 'contained', color ?? 'primary');
  const border = hasFocus && focusBorderColor ? focusBorderColor : borderColor;

  return {
    datatestid,
    maxWidth: '504px',
    'data-track': props['data-track'],
    '&.MuiButton-root': {
      ...(hasFocus && focusColor ? { color: focusColor } : {}),
      'background-color':
        hasFocus && focusBackgroundColor
          ? focusBackgroundColor
          : backgroundColor,
      borderWidth: hasFocus ? '2px' : '1px',
      borderColor: border,
    },
    '& .MuiButton-startIcon > svg:first-child': {
      filter: props.disabled ? 'contrast(0%)' : 'contrast(100%)',
    },
    '& .MuiButton-endIcon > svg:last-child': {
      filter: props.disabled ? 'contrast(0%)' : 'contrast(100%)',
    },
  };
});

const LBTButton: React.FC<LBTButtonProps> = ({
  datatestid = '',
  children,
  textProps,
  size,
  ...props
}: LBTButtonProps) => {
  // const startIcon = useRef<React.ReactNode>(props.startIcon);
  // const endIcon = useRef<React.ReactNode>(props.endIcon);
  // const disabled = useRef<React.ReactNode>(props.disabled);

  const getVariant = () => {
    if (size === 'small') return 'buttonSmall';
    if (size === 'medium') return 'buttonMedium';
    return 'buttonLarge';
  };

  return (
    <StyledButton
      data-track={datatestid}
      datatestid={`lbt-button-${datatestid}`}
      size={size}
      {...props}
    >
      <LBTLabel
        datatestid={`lbt-button-label-${datatestid}`}
        data-track={datatestid}
        {...textProps}
        variant={getVariant()}
      >
        {children}
      </LBTLabel>
    </StyledButton>
  );
};

export default LBTButton;
