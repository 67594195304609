export class COLORS {
  private _PRIMARY_MAIN_DEFAULT = '#59A5FF';

  private _PRIMARY_MAIN = '#59A5FF';
  private _PRIMARY_IPERLIGHT = '#E4F2FF';
  private _PRIMARY_SUPERLIGHT = '#BFDEFF';
  private _PRIMARY_LIGHT = '#98CAFF';
  private _PRIMARY_MEDIUM = '#4A94FD';
  private _PRIMARY_MEDIUMDARK = '#4886EE';
  private _PRIMARY_DARK = '#2D78F5';
  private _PRIMARY_SUPERDARK = '#225FC4';
  private _PRIMARY_CONTRAST = '#282828';

  private _WHITE = '#FFFFFF';
  private _BLACK = '#000000';
  private _DIVIDER = '#E8E8E8';
  private _DISABLED = '#9B9B9B';
  private _BW_GREYS_JET_BLACK = '#282828';
  private _BW_GREYS_MAIN = '#F0F0F0';
  private _BW_GREYS_DARK = '#3B3B3B';
  private _BW_GREYS_MEDIUM = '#5B5B5B';
  private _BW_GREYS_MEDIUM_LIGHT = '#9B9B9B';
  private _BW_GREYS_SUPERLIGHT = '#E8E8E8';
  private _NEGATIVE = '#F0F0F0';
  private _STATUS_INFO_BLUE_MAIN = '#386CCC0A';
  private _BW_GREYS_MEDIUM_DARK = '#646464';
  private _BW_GREYS_PALE_MEDIUM = '#B4B4B4';
  private _BW_GREYS_IPERLIGHT = '#F3F3F3';
  private _BW_GREYS_LIGHT = '#D4D4D4';

  private _ERROR_MAIN = '#CC3838';
  private _ERROR_DARK = '#A41F1D';
  private _ERROR_LIGHT = '#FFC6B4';
  private _ERROR_CONTRAST = '#FFFFFF';

  private _WARNING_MAIN = '#FF8900';
  private _WARNING_DARK = '#DE5C00';
  private _WARNING_LIGHT = '#FFB44C';
  private _WARNING_CONTRAST = '#2C2E30';

  private _INFO_MAIN = '#386CCC';
  private _INFO_DARK = '#1F3687';
  private _INFO_LIGHT = '#98CFFF';
  private _INFO_CONTRAST = '#FFFFFF';

  private _SUCCESS_MAIN = '#2D7D5D';
  private _SUCCESS_DARK = '#1B5136';
  private _SUCCESS_LIGHT = '#62B99C';
  private _SUCCESS_CONTRAST = '#FFFFFF';

  private _NEUTRAL = '#787878';

  private _LEMON_CAKE_MAIN = '#FFFA74';
  private _LEMON_CAKE_SUPERLIGHT = '#FFF3D9';
  private _LEMON_CAKE_DARK = '#F3E900';

  private _TANGERINE_MAIN = '#FFA084';
  private _TANGERINE_MEDIUM = '#FC9373';
  private _TANGERINE_SUPERLIGHT = '#FFDDD3';
  private _TANGERINE_DARK = '#F95F30';
  private _TANGERINE_SUPERDARK = '#BF3300';

  private _MINT_TONIC_MEDIUM = '#00CB8D';
  private _MINT_TONIC_MAIN = '#9AE6C8';
  private _MINT_TONIC_LIGHT = '#B1F2D8';
  private _MINT_TONIC_IPERLIGHT = '#E6F9F1';
  private _MINT_TONIC_DARK = '#00A26F';
  private _MINT_TONIC_SUPERDARK = '#007D64';

  private _BERRY_POPSICLE_MAIN = '#F7C4FB';
  private _BERRY_POPSICLE_MEDIUM = '#EF9EF5';
  private _BERRY_POPSICLE_LIGHT = '#FCD7FF';
  private _BERRY_POPSICLE_DARK = '#DD54E6';
  private _BERRY_POPSICLE_SUPERDARK = '#B600B6';

  private _LAVANDER_MAIN = '#C49DFF';
  private _LAVANDER_DARK = '#8448D7';

  private static _INSTANCE: COLORS | undefined = undefined;

  public static getInstance() {
    if (this._INSTANCE === undefined) {
      this._INSTANCE = new COLORS();
    }

    return this._INSTANCE;
  }

  constructor() {}

  get PRIMARY_MAIN_DEFAULT() {
    return this._PRIMARY_MAIN_DEFAULT;
  }

  get PRIMARY_MAIN() {
    return this._PRIMARY_MAIN;
  }

  set PRIMARY_MAIN(value) {
    this._PRIMARY_MAIN = value;
  }

  get PRIMARY_DARK() {
    return this._PRIMARY_DARK;
  }

  set PRIMARY_DARK(value) {
    this._PRIMARY_DARK = value;
  }

  get PRIMARY_SUPERDARK() {
    return this._PRIMARY_SUPERDARK;
  }

  set PRIMARY_SUPERDARK(value) {
    this._PRIMARY_SUPERDARK = value;
  }

  get PRIMARY_LIGHT() {
    return this._PRIMARY_LIGHT;
  }

  set PRIMARY_LIGHT(value) {
    this._PRIMARY_LIGHT = value;
  }

  get PRIMARY_MEDIUM() {
    return this._PRIMARY_MEDIUM;
  }

  set PRIMARY_MEDIUM(value) {
    this._PRIMARY_MEDIUM = value;
  }

  get PRIMARY_MEDIUMDARK() {
    return this._PRIMARY_MEDIUMDARK;
  }

  set PRIMARY_MEDIUMDARK(value) {
    this._PRIMARY_MEDIUMDARK = value;
  }

  get PRIMARY_SUPERLIGHT() {
    return this._PRIMARY_SUPERLIGHT;
  }

  set PRIMARY_SUPERLIGHT(value) {
    this._PRIMARY_SUPERLIGHT = value;
  }

  get PRIMARY_IPERLIGHT() {
    return this._PRIMARY_IPERLIGHT;
  }

  set PRIMARY_IPERLIGHT(value) {
    this._PRIMARY_IPERLIGHT = value;
  }

  get PRIMARY_CONTRAST() {
    return this._PRIMARY_CONTRAST;
  }

  set PRIMARY_CONTRAST(value) {
    this._PRIMARY_CONTRAST = value;
  }

  get ERROR_MAIN() {
    return this._ERROR_MAIN;
  }

  set ERROR_MAIN(value) {
    this._ERROR_MAIN = value;
  }

  get ERROR_DARK() {
    return this._ERROR_DARK;
  }

  set ERROR_DARK(value) {
    this._ERROR_DARK = value;
  }

  get ERROR_LIGHT() {
    return this._ERROR_LIGHT;
  }

  set ERROR_LIGHT(value) {
    this._ERROR_LIGHT = value;
  }

  get ERROR_CONTRAST() {
    return this._ERROR_CONTRAST;
  }

  set ERROR_CONTRAST(value) {
    this._ERROR_CONTRAST = value;
  }

  get WARNING_MAIN() {
    return this._WARNING_MAIN;
  }

  set WARNING_MAIN(value) {
    this._WARNING_MAIN = value;
  }

  get WARNING_DARK() {
    return this._WARNING_DARK;
  }

  set WARNING_DARK(value) {
    this._WARNING_DARK = value;
  }

  get WARNING_LIGHT() {
    return this._WARNING_LIGHT;
  }

  set WARNING_LIGHT(value) {
    this._WARNING_LIGHT = value;
  }

  get WARNING_CONTRAST() {
    return this._WARNING_CONTRAST;
  }

  set WARNING_CONTRAST(value) {
    this._WARNING_CONTRAST = value;
  }

  get INFO_MAIN() {
    return this._INFO_MAIN;
  }

  set INFO_MAIN(value) {
    this._INFO_MAIN = value;
  }

  get INFO_DARK() {
    return this._INFO_DARK;
  }

  set INFO_DARK(value) {
    this._INFO_DARK = value;
  }

  get INFO_LIGHT() {
    return this._INFO_LIGHT;
  }

  set INFO_LIGHT(value) {
    this._INFO_LIGHT = value;
  }

  get INFO_CONTRAST() {
    return this._INFO_CONTRAST;
  }

  set INFO_CONTRAST(value) {
    this._INFO_CONTRAST = value;
  }

  get SUCCESS_MAIN() {
    return this._SUCCESS_MAIN;
  }

  set SUCCESS_MAIN(value) {
    this._SUCCESS_MAIN = value;
  }

  get SUCCESS_DARK() {
    return this._SUCCESS_DARK;
  }

  set SUCCESS_DARK(value) {
    this._SUCCESS_DARK = value;
  }

  get SUCCESS_LIGHT() {
    return this._SUCCESS_LIGHT;
  }

  set SUCCESS_LIGHT(value) {
    this._SUCCESS_LIGHT = value;
  }

  get SUCCESS_CONTRAST() {
    return this._SUCCESS_CONTRAST;
  }

  set SUCCESS_CONTRAST(value) {
    this._SUCCESS_CONTRAST = value;
  }

  get NEUTRAL() {
    return this._NEUTRAL;
  }

  set NEUTRAL(value) {
    this._NEUTRAL = value;
  }

  get WHITE() {
    return this._WHITE;
  }

  set WHITE(value) {
    this._WHITE = value;
  }

  get BLACK() {
    return this._BLACK;
  }

  set BLACK(value) {
    this._BLACK = value;
  }

  get DIVIDER() {
    return this._DIVIDER;
  }

  set DIVIDER(value) {
    this._DIVIDER = value;
  }

  get DISABLED() {
    return this._DISABLED;
  }

  set DISABLED(value) {
    this._DISABLED = value;
  }

  get BW_GREYS_JET_BLACK() {
    return this._BW_GREYS_JET_BLACK;
  }

  set BW_GREYS_JET_BLACK(value) {
    this._BW_GREYS_JET_BLACK = value;
  }

  get TANGERINE_MAIN() {
    return this._TANGERINE_MAIN;
  }

  set TANGERINE_MAIN(value) {
    this._TANGERINE_MAIN = value;
  }

  get TANGERINE_MEDIUM() {
    return this._TANGERINE_MEDIUM;
  }

  set TANGERINE_MEDIUM(value) {
    this._TANGERINE_MEDIUM = value;
  }

  get TANGERINE_DARK() {
    return this._TANGERINE_DARK;
  }

  set TANGERINE_DARK(value) {
    this._TANGERINE_DARK = value;
  }

  get TANGERINE_SUPERDARK() {
    return this._TANGERINE_SUPERDARK;
  }

  set TANGERINE_SUPERDARK(value) {
    this._TANGERINE_SUPERDARK = value;
  }

  get MINT_TONIC_MAIN() {
    return this._MINT_TONIC_MAIN;
  }

  set MINT_TONIC_MAIN(value) {
    this._MINT_TONIC_MAIN = value;
  }

  get BERRY_POPSICLE_LIGHT() {
    return this._BERRY_POPSICLE_LIGHT;
  }

  set BERRY_POPSICLE_LIGHT(value) {
    this._BERRY_POPSICLE_LIGHT = value;
  }

  get MINT_TONIC_LIGHT() {
    return this._MINT_TONIC_LIGHT;
  }

  set MINT_TONIC_LIGHT(value) {
    this._MINT_TONIC_LIGHT = value;
  }

  get MINT_TONIC_IPERLIGHT() {
    return this._MINT_TONIC_IPERLIGHT;
  }

  set MINT_TONIC_IPERLIGHT(value) {
    this._MINT_TONIC_IPERLIGHT = value;
  }

  get MINT_TONIC_DARK() {
    return this._MINT_TONIC_DARK;
  }

  set MINT_TONIC_DARK(value) {
    this._MINT_TONIC_DARK = value;
  }

  get MINT_TONIC_SUPERDARK() {
    return this._MINT_TONIC_SUPERDARK;
  }

  set MINT_TONIC_SUPERDARK(value) {
    this._MINT_TONIC_SUPERDARK = value;
  }

  get BW_GREYS_MAIN() {
    return this._BW_GREYS_MAIN;
  }

  set BW_GREYS_MAIN(value) {
    this._BW_GREYS_MAIN = value;
  }

  get BW_GREYS_DARK() {
    return this._BW_GREYS_DARK;
  }

  set BW_GREYS_DARK(value) {
    this._BW_GREYS_DARK = value;
  }

  get BW_GREYS_MEDIUM() {
    return this._BW_GREYS_MEDIUM;
  }

  set BW_GREYS_MEDIUM(value) {
    this._BW_GREYS_MEDIUM = value;
  }

  get BW_GREYS_MEDIUM_LIGHT() {
    return this._BW_GREYS_MEDIUM_LIGHT;
  }

  set BW_GREYS_MEDIUM_LIGHT(value) {
    this._BW_GREYS_MEDIUM_LIGHT = value;
  }

  get BW_GREYS_SUPERLIGHT() {
    return this._BW_GREYS_SUPERLIGHT;
  }

  set BW_GREYS_SUPERLIGHT(value) {
    this._BW_GREYS_SUPERLIGHT = value;
  }

  get BW_GREYS_IPERLIGHT() {
    return this._BW_GREYS_IPERLIGHT;
  }

  set BW_GREYS_IPERLIGHT(value) {
    this._BW_GREYS_IPERLIGHT = value;
  }

  get BW_GREYS_LIGHT() {
    return this._BW_GREYS_LIGHT;
  }

  set BW_GREYS_LIGHT(value) {
    this._BW_GREYS_LIGHT = value;
  }

  get TANGERINE_SUPERLIGHT() {
    return this._TANGERINE_SUPERLIGHT;
  }

  set TANGERINE_SUPERLIGHT(value) {
    this._TANGERINE_SUPERLIGHT = value;
  }

  get LAVANDER_MAIN() {
    return this._LAVANDER_MAIN;
  }

  set LAVANDER_MAIN(value) {
    this._LAVANDER_MAIN = value;
  }

  get LAVANDER_DARK() {
    return this._LAVANDER_DARK;
  }

  set LAVANDER_DARK(value) {
    this._LAVANDER_DARK = value;
  }

  get BERRY_POPSICLE_MAIN() {
    return this._BERRY_POPSICLE_MAIN;
  }

  set BERRY_POPSICLE_MAIN(value) {
    this._BERRY_POPSICLE_MAIN = value;
  }

  get BERRY_POPSICLE_MEDIUM() {
    return this._BERRY_POPSICLE_MEDIUM;
  }

  set BERRY_POPSICLE_MEDIUM(value) {
    this._BERRY_POPSICLE_MEDIUM = value;
  }

  get BERRY_POPSICLE_DARK() {
    return this._BERRY_POPSICLE_DARK;
  }

  set BERRY_POPSICLE_DARK(value) {
    this._BERRY_POPSICLE_DARK = value;
  }

  get BERRY_POPSICLE_SUPERDARK() {
    return this._BERRY_POPSICLE_SUPERDARK;
  }

  set BERRY_POPSICLE_SUPERDARK(value) {
    this._BERRY_POPSICLE_SUPERDARK = value;
  }

  get LEMON_CAKE_MAIN() {
    return this._LEMON_CAKE_MAIN;
  }

  set LEMON_CAKE_MAIN(value) {
    this._LEMON_CAKE_MAIN = value;
  }

  get LEMON_CAKE_SUPERLIGHT() {
    return this._LEMON_CAKE_SUPERLIGHT;
  }

  set LEMON_CAKE_SUPERLIGHT(value) {
    this._LEMON_CAKE_SUPERLIGHT = value;
  }

  get LEMON_CAKE_DARK() {
    return this._LEMON_CAKE_DARK;
  }

  set LEMON_CAKE_DARK(value) {
    this._LEMON_CAKE_DARK = value;
  }

  get MINT_TONIC_MEDIUM() {
    return this._MINT_TONIC_MEDIUM;
  }

  set MINT_TONIC_MEDIUM(value) {
    this._MINT_TONIC_MEDIUM = value;
  }

  get NEGATIVE() {
    return this._NEGATIVE;
  }

  set NEGATIVE(value) {
    this._NEGATIVE = value;
  }

  get STATUS_INFO_BLUE_MAIN() {
    return this._STATUS_INFO_BLUE_MAIN;
  }

  set STATUS_INFO_BLUE_MAIN(value) {
    this._STATUS_INFO_BLUE_MAIN = value;
  }

  get BW_GREYS_MEDIUM_DARK() {
    return this._BW_GREYS_MEDIUM_DARK;
  }

  set BW_GREYS_MEDIUM_DARK(value) {
    this._BW_GREYS_MEDIUM_DARK = value;
  }

  get BW_GREYS_PALE_MEDIUM() {
    return this._BW_GREYS_PALE_MEDIUM;
  }

  set BW_GREYS_PALE_MEDIUM(value) {
    this._BW_GREYS_PALE_MEDIUM = value;
  }
}
