import { useEffect, useRef } from 'react';
import { useRecoilValue } from 'recoil';
import { currentUserState, currentCompanyConfigState, TEST_DOMAINS } from '..';
import TagManager from 'react-gtm-module';
import md5 from 'md5';
import { useLocation } from 'react-router-dom';

interface DataLayerProps {
  key: string;
  value: string;
}

function useTrackAnalytics(
  props?: Array<DataLayerProps>,
  event?: 'page_view' | 'questionario',
) {
  const user = useRecoilValue(currentUserState);
  const company = useRecoilValue(currentCompanyConfigState);
  const domain = user.email?.substring(user.email?.indexOf('@') + 1);

  const triggered = useRef<boolean>(false);
  const location = useLocation();
  const storedLocation = useRef<string>('');

  useEffect(() => {
    if (
      location.pathname !== storedLocation.current ||
      location.pathname === '/questionary'
    ) {
      triggered.current = false;
      storedLocation.current = location.pathname;
    }

    const hasBeenTriggered = triggered.current;
    let dataLayer = {};

    if (!hasBeenTriggered) {
      if (user.email && company.id && domain) {
        dataLayer = {
          id_user: md5(user.email),
          user_test: TEST_DOMAINS.includes(domain) ? 'true' : 'false',
          company: `company-${company.id}`,
          timestamp: new Date().toISOString(),
          is_prod_env: process.env.REACT_APP_ENV === 'prod' ? 'true' : 'false',
        };
      }

      if (props)
        props.forEach((prop: DataLayerProps) => {
          Object.assign(dataLayer, { [prop.key]: prop.value });
        });

      if (props?.length === 0) return;
      console.log('@@@tagManager', dataLayer);
      TagManager.dataLayer({ dataLayer: dataLayer });

      triggered.current = true;
    }

    // eslint-disable-next-line
  }, [props]);
}

export default useTrackAnalytics;
