import { authPost, PATH, authGetAll, authPut, authDelete } from '../../http';
import { Ente, GetAllEnte, GetFilter, Id, Post, Put } from '../types';

export const postEnte = async (item: Post<Ente>) => {
  return await authPost(`api/v1/${PATH.ENTE}/`, item);
};

export const getEnteById = async ({ id }: Id) => {
  return await authGetAll(`api/v1/${PATH.ENTE}/${PATH.PUBLIC}/${id}`);
};

export const getAllEnte = async ({
  limit,
  skip,
  is_public_agency,
}: GetFilter & GetAllEnte) => {
  return await authGetAll(`api/v1/${PATH.ENTE}/${PATH.PUBLIC}/`, {
    limit,
    skip,
    is_public_agency,
  });
};

export const putEnte = async (item: Put<Ente>) => {
  return await authPut(`api/v1/${PATH.ENTE}`, item);
};

export const deleteEnte = async ({ id }: Id) => {
  return await authDelete(`api/v1/${PATH.ENTE}/${id}`);
};
