import { ColDef } from 'ag-grid-community';
import { useEffect, useState } from 'react';
import { useRecoilCallback, useSetRecoilState } from 'recoil';
import GridLayout from '../components/GridLayout';
import {
  Category,
  Modal,
  categoriesState,
  deleteCategoryCallback,
  getAllCategoriesCallback,
  getAllCategoriesWithSubcategories,
} from '@laborability/commons';
import { CategoryForm } from '../components/Forms';
import { GridImage } from '../components/GridImage';
import { ImageView } from '../components/ImageView';
import { useGrid } from '../hooks/useGrid';
import { LBTModal } from '@laborability/components';
import { appBarState } from '../recoil/states/appBar';

export function CategoriesPage() {
  const [modal, setModal] = useState<Modal | null>(null);
  const getAllCategories = useRecoilCallback(getAllCategoriesCallback, []);
  const deleteCategory = useRecoilCallback(deleteCategoryCallback, []);
  const setAppBarName = useSetRecoilState(appBarState);

  const { rowData, handleFetchAll, handleDelete } = useGrid<Category>({
    state: categoriesState,
    onFetch: getAllCategories,
    onDelete: deleteCategory,
  });
  const { orderedCategories } = getAllCategoriesWithSubcategories(rowData);

  const columnDefs: ColDef[] = [
    {
      field: 'id',
      maxWidth: 100,
      filter: 'agNumberColumnFilter',
      resizable: false,
      headerName: 'ID',
    },
    {
      field: 'name',
      flex: 2,
      filter: 'agTextColumnFilter',
      headerName: 'Categoria',
    },
    {
      field: 'parent_id',
      flex: 2,
      filter: false,
      resizable: false,
      headerName: 'Parent',
      valueFormatter: params =>
        params.api.getRowNode(params.data.parent_id)?.data.name ?? '-',
    },
    {
      field: 'description',
      flex: 2,
      filter: 'agTextColumnFilter',
      headerName: 'Descrizione',
    },
    {
      field: 'icon',
      filter: false,
      flex: 1,
      headerName: 'Icona',
      width: 100,
      cellRenderer: GridImage,
      cellRendererParams: {
        callback: async (data: Category) => {
          setModal({
            children: (
              <ImageView
                image={data.icon ? data.icon : ''}
                onClose={() => setModal(null)}
              />
            ),
            title: 'Icona Categoria',
          });
        },
      },
    },
    {
      field: 'image',
      filter: false,
      flex: 1,
      headerName: 'Immagine',
      width: 100,
      cellRenderer: GridImage,
      cellRendererParams: {
        callback: async (data: Category) => {
          setModal({
            children: (
              <ImageView
                image={data?.image ?? ''}
                onClose={() => setModal(null)}
              />
            ),
            title: 'Immagine Categoria',
          });
        },
      },
    },
  ];

  useEffect(() => {
    setAppBarName('Registrazione categorie');
  }, []);

  return (
    <>
      <GridLayout<any>
        pageName="Categorie"
        columnDefs={columnDefs}
        rowData={orderedCategories}
        handleFetchAll={handleFetchAll}
        handleDelete={handleDelete}
        FormComponent={CategoryForm}
        recordNameAccessor={item => item.name ?? ''}
        rowHeight={100}
      />
      {!!modal && (
        <LBTModal open={!!modal} onXClose={() => setModal(null)} {...modal} />
      )}
    </>
  );
}
