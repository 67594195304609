import { Box, Stack, Typography } from '@mui/material';
import { LBTButton } from '@laborability/components';

export const DeleteAlert: React.FC<{
  message: string;
  onDelete: (id: number) => Promise<void>;
  onClose: () => void;
  entityId: number;
}> = ({ message, onDelete, onClose, entityId }) => {
  return (
    <Stack display={'flex'} flexDirection={'column'}>
      <Typography sx={{ padding: '16px', fontSize: '1.2rem' }}>
        {message}
      </Typography>
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
        <LBTButton variant="outlined" color="primary" onClick={onClose}>
          Annulla
        </LBTButton>
        <LBTButton
          sx={{ marginLeft: '8px' }}
          variant="contained"
          color="primary"
          onClick={async () => {
            await onDelete(entityId);
            onClose();
          }}
        >
          Conferma
        </LBTButton>
      </Box>
    </Stack>
  );
};
