import { ColDef, ICellRendererParams } from 'ag-grid-community';
import { useRecoilCallback, useSetRecoilState } from 'recoil';
import GridLayout from '../components/GridLayout';
import { EnteForm } from '../components/Forms';
import { useGrid } from '../hooks/useGrid';
import { Checkbox } from '@mui/material';
import {
  deleteEnteCallback,
  getAllEnteCallback,
  Ente,
  agenciesState,
} from '@laborability/commons';
import { useEffect } from 'react';
import { appBarState } from '../recoil/states/appBar';

const columnDefs: ColDef[] = [
  {
    field: 'id',
    maxWidth: 100,
    filter: 'agNumberColumnFilter',
    resizable: false,
    headerName: 'ID',
  },
  {
    field: 'name',
    flex: 2,
    filter: 'agTextColumnFilter',
    headerName: 'Ente',
  },
  {
    field: 'contract.name',
    flex: 2,
    filter: 'agTextColumnFilter',
    headerName: 'Contratto',
  },
  {
    field: 'is_public_agency',
    flex: 2,
    filter: 'agNumberColumnFilter',
    filterParams: {
      maxNumConditions: 1,
      filterOptions: [
        {
          displayKey: 'Entrambi',
          displayName: 'Entrambi',
          predicate: () => true,
          numberOfInputs: 0,
        },
        {
          displayKey: 'Sì',
          displayName: 'Sì',
          // @ts-ignore
          predicate: (_, cellValue) => cellValue,
          numberOfInputs: 0,
        },
        {
          displayKey: 'No',
          displayName: 'No',
          // @ts-ignore
          predicate: (_, cellValue) => !cellValue,
          numberOfInputs: 0,
        },
      ],
      defaultOption: 'Entrambi',
    },
    headerName: 'Ente Pubblico',
    cellRenderer: (params: ICellRendererParams) => (
      <Checkbox checked={params.value} disabled />
    ),
  },
];

export function EntesPage() {
  const getAllEntes = useRecoilCallback(getAllEnteCallback, []);
  const deleteEnte = useRecoilCallback(deleteEnteCallback, []);
  const setAppBarName = useSetRecoilState(appBarState);

  const { rowData, handleFetchAll, handleDelete } = useGrid<Ente>({
    state: agenciesState,
    onFetch: getAllEntes,
    onDelete: deleteEnte,
  });

  useEffect(() => {
    setAppBarName('Registrazione enti');
  }, []);

  return (
    <GridLayout<Ente>
      pageName="Ente"
      columnDefs={columnDefs}
      rowData={rowData}
      handleFetchAll={async () => {
        return await handleFetchAll({ limit: 100000 });
      }}
      handleDelete={handleDelete}
      FormComponent={EnteForm}
      recordNameAccessor={item => item.name ?? ''}
    />
  );
}
