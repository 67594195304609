import { AlertTitle, Palette, PaletteColor, Typography } from '@mui/material';
import Alert, { AlertProps } from '@mui/material/Alert';
import { styled } from '@mui/material/styles';
import { COLORS, FONTS } from '../../utils';
import IconBookmarkComponent from '../Icons/IconBookmarkComponent';
import IconHappyFaceComponent from '../Icons/IconHappyFaceComponent';
import IconInfoComponent from '../Icons/IconInfoComponent';
import IconWarningComponent from '../Icons/IconWarningComponent';
import { IconsSize, IconsStyle } from '../../enums';
import LBTLabel from '../Label';

export type LBTAlertProps = AlertProps & {
  title: string;
  message: string;
  onClose?: () => void;
  datatestid: string;
};

// "primary" | "error" | "warning" | "info" | "success"
type PaletteKey = keyof {
  [Key in keyof Palette as Palette[Key] extends PaletteColor
    ? Key
    : never]: true;
};

// "light" | "dark" | "superdark" | "main" | "contrastText"
type PaletteColorKey = keyof PaletteColor;

const StyledAlert = styled(Alert)<
  AlertProps & {
    borderRadius?: string;
    backgroundColor?: string;
    borderColor?: string;
    datatestid: string;
    'data-track': string;
  }
>(
  ({
    theme,
    borderRadius = '15px',
    variant,
    severity,
    datatestid,
    ...props
  }) => ({
    borderRadius,
    datatestid,
    'data-track': props['data-track'],
  }),
);

const LBTAlert: React.FC<LBTAlertProps> = ({
  title,
  message,
  severity,
  icon,
  variant,
  datatestid = '',
  onClose,
  ...props
}: LBTAlertProps) => {
  const getAlertIcon = () => {
    if (icon) return icon;
    switch (severity) {
      case 'error':
        return (
          <IconWarningComponent
            size={IconsSize.LARGE}
            style={IconsStyle.OUTLINE}
            color={
              variant === 'contained' || variant === 'filled'
                ? COLORS.getInstance().WHITE
                : COLORS.getInstance().ERROR_MAIN
            }
          />
        );
      case 'success':
        return (
          <IconHappyFaceComponent
            size={IconsSize.LARGE}
            style={IconsStyle.OUTLINE}
            color={
              variant === 'contained' || variant === 'filled'
                ? COLORS.getInstance().WHITE
                : COLORS.getInstance().SUCCESS_MAIN
            }
          />
        );
      case 'info':
        return (
          <IconInfoComponent
            size={IconsSize.LARGE}
            style={IconsStyle.OUTLINE}
            color={
              variant === 'contained' || variant === 'filled'
                ? COLORS.getInstance().WHITE
                : COLORS.getInstance().INFO_MAIN
            }
          />
        );
      case 'neutral':
        return (
          <IconBookmarkComponent
            size={IconsSize.LARGE}
            style={IconsStyle.OUTLINE}
            color={
              variant === 'contained' || variant === 'filled'
                ? COLORS.getInstance().WHITE
                : COLORS.getInstance().NEUTRAL
            }
          />
        );
      default:
        return undefined;
    }
  };

  return (
    <StyledAlert
      data-track={`lbt-alert-${datatestid}`}
      datatestid={`lbt-alert-${datatestid}`}
      variant={variant}
      severity={severity}
      icon={getAlertIcon()}
      onClose={onClose}
      style={{
        textAlign: 'left',
      }}
      {...props}
    >
      <AlertTitle
        fontFamily={FONTS.SOURCE_SANS_PRO_BOLD}
        fontSize="17px"
        lineHeight="150%"
        letterSpacing="2%"
        component="strong"
      >
        {title}
      </AlertTitle>
      <LBTLabel
        color={theme =>
          variant === 'outlined'
            ? COLORS.getInstance().WHITE
            : theme.palette[severity as PaletteKey]['main' as PaletteColorKey]
        }
        variant="alertDescription"
      >
        {message}
      </LBTLabel>
    </StyledAlert>
  );
};

export default LBTAlert;
