import { Benefit } from './benefit';
import { Category } from './category';
import { Tag } from './tag';
import { Ente } from './ente';
import { Province, Region } from './municipalities';
import { GetFilter, Id } from './common';

export interface Misura {
  id?: number;
  name?: string;
  description?: string;
  video_path?: string;
  image_path?: string;
  web_link?: string;
  agency_id?: number;
  categories_ids?: Array<number>;
  categories?: Array<Category>;
  tag_ids?: Array<number>;
  benefit_type_id?: number;
  benefit_type?: Array<Benefit>;
  how_it_works?: string;
  target_audience?: string;
  access_requirements?: string;
  bonus_value?: string;
  application_period?: string;
  application_process?: string;
  start_date?: string;
  end_date?: string;
  expiring_soon?: boolean;
  news?: boolean;
  upcoming?: boolean;
  expired?: boolean;
  for_all_incomes?: boolean;
  question_online_or_phone?: boolean;
  initial_range?: string;
  final_range?: string;
  national?: boolean;
  regional?: boolean;
  regions_ids?: number[];
  provinces_ids?: number[];
  agency?: Ente;
  visit_count?: number;
  tags?: Tag[];
  regions?: Region[];
  province?: Province[];
  draft?: boolean;
  main_category?: number;
  sub_category?: number;
}

export interface SearchMisura extends GetFilter {
  query: string;
  published?: boolean;
  progressive?: boolean;
}

export interface GetMisuraByTag extends GetFilter {
  tag_id: number;
  published?: boolean;
  progressive?: boolean;
}

export interface GetMisuraMostPopular {
  limit: number;
  published?: boolean;
}

export interface GetMisuraByCategory extends GetFilter, Id {
  published?: boolean;
  progressive?: boolean;
}

export interface AdvancedSearchMisura extends GetFilter {
  category_id: number;
  published?: boolean;
  progressive?: boolean;
  news?: boolean;
  upcoming?: boolean;
  expiring_soon?: boolean;
  for_all_incomes?: boolean;
  question_online_or_phone?: boolean;
  region_id?: number[];
  benefit_type_id?: number[];
  national?: boolean;
  regional?: boolean;
  sub_categories?: number[];
}

export interface GetAllMisura extends GetFilter {
  published?: boolean;
  progressive?: boolean;
}

export class MisuraClass implements Misura {
  id = 0;
  name = '';
  description = '';
  video_path = '';
  image_path = '';
  web_link = '';
  agency_id = undefined;
  categories_ids = [];
  categories = [];
  tag_ids = [];
  benefit_type_id = undefined;
  benefit_type = [];
  how_it_works = '';
  target_audience = '';
  access_requirements = '';
  bonus_value = '';
  application_period = '';
  application_process = '';
  start_date = undefined;
  end_date = undefined;
  expiring_soon = false;
  news = false;
  upcoming = false;
  expired = false;
  for_all_incomes = false;
  question_online_or_phone = false;
  initial_range = '';
  final_range = '';
  national = false;
  regional = false;
  regions_ids = [];
  provinces_ids = [];
  agency = undefined;
  visit_count = undefined;
  tags = [];
  regions = [];
  province = [];
  draft = false;
  main_category = undefined;
  sub_category = undefined;
}
