import { ColDef } from 'ag-grid-community';
import { useEffect, useState } from 'react';
import { useRecoilCallback, useSetRecoilState } from 'recoil';
import GridLayout from '../components/GridLayout';
import { TagForm } from '../components/Forms';
import { useGrid } from '../hooks/useGrid';
import {
  getAllTagsCallback,
  deleteTagCallback,
  Tag,
  tagsState,
  Modal,
} from '@laborability/commons';
import { GridImage } from '../components/GridImage';
import { ImageView } from '../components/ImageView';
import { LBTModal } from '@laborability/components';
import { appBarState } from '../recoil/states/appBar';

export function TagsPage() {
  const [modal, setModal] = useState<Modal | null>(null);
  const getAllTags = useRecoilCallback(getAllTagsCallback, []);
  const deleteTag = useRecoilCallback(deleteTagCallback, []);
  const setAppBarName = useSetRecoilState(appBarState);

  const { rowData, handleFetchAll, handleDelete } = useGrid<Tag>({
    state: tagsState,
    onFetch: getAllTags,
    onDelete: deleteTag,
  });

  const columnDefs: ColDef[] = [
    {
      field: 'id',
      maxWidth: 100,
      filter: 'agNumberColumnFilter',
      resizable: false,
      headerName: 'ID',
    },
    { field: 'name', flex: 2, filter: 'agTextColumnFilter', headerName: 'Tag' },
    {
      field: 'description',
      flex: 2,
      filter: 'agTextColumnFilter',
      headerName: 'Descrizione',
    },
    {
      field: 'image',
      filter: false,
      flex: 1,
      headerName: 'Immagine',
      width: 100,
      cellRenderer: GridImage,
      cellRendererParams: {
        callback: async (data: Tag) => {
          setModal({
            children: (
              <ImageView
                image={data?.image ?? ''}
                onClose={() => setModal(null)}
              />
            ),
            title: 'Immagine Tag',
          });
        },
      },
    },
  ];

  useEffect(() => {
    setAppBarName('Registrazione tags');
  }, []);

  return (
    <>
      <GridLayout<Tag>
        pageName="TAG"
        columnDefs={columnDefs}
        rowData={rowData}
        handleFetchAll={handleFetchAll}
        handleDelete={handleDelete}
        FormComponent={TagForm}
        recordNameAccessor={item => item.name ?? ''}
        rowHeight={100}
      />
      {!!modal && (
        <LBTModal open={!!modal} onXClose={() => setModal(null)} {...modal} />
      )}
    </>
  );
}
