import { Misura, MisuraClass } from '../types';
import { atom } from 'recoil';
import { getUUID } from '../../utils';

export const measureState = atom<Misura>({
  key: getUUID(),
  default: new MisuraClass(),
});

export const measuresState = atom<Misura[]>({
  key: getUUID(),
  default: [],
});

export const measureLoaderState = atom<boolean>({
  key: getUUID(),
  default: false,
});

export const measuresLoaderState = atom<boolean>({
  key: getUUID(),
  default: false,
});
